import React from "react"
import GlobalStyle from "../components/layout/GlobalStyle"
import ResultHeader from "../components/resultComponents/ResultHeader"
import FilterHeader from "../components/compareComponents/FilterHeader"
import styled from "styled-components"
import PageButton from "../components/layout/buttons/PageButton"
import { Helmet } from "react-helmet"
import HeaderWithPhoto from "../components/layout/header/HeaderWithPhoto"
import { useProducts } from "../hooks/useProducts"
import { findInnerFilters, findHotelFilters } from "../utils/helpFunctions"
import { graphql } from "gatsby"
import { routes } from "../utils/routeHeaders"
import { device } from "../utils/mediaQueries"

const Header1 = styled.h1`
  color: white;
  font-size: 35px;
  line-height: 3.5rem;
  font-weight: 800;
  text-shadow: 2px 2px 4px #0000008f;
`

const Header2 = styled.h2`
  color: white;
  font-size: 20px;
  line-height: 2.5rem;
  font-weight: 500;
  text-shadow: 2px 2px 4px #0000008f;
  letter-spacing: 0.96px;
`
const Header3 = styled.h3`
  color: black;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.96px;
  text-align: center;
  margin: 30px;
`

const Container = styled.div`
  margin: 4rem 12rem;
  @media ${device.tablet} {
    margin: 20px 30px;
  }
`

const TextContainer = styled.div`
  margin: 4rem 12rem;
  @media ${device.tablet} {
    margin: 20px 30px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 25px;
`
const LoadingText = styled.div`
  font-size: 30px;
  text-align: center;
  margin-top: 30px;
`

const Template = ({ data }) => {
  const isHotel = data.markdownRemark.frontmatter.filter.includes("majoitus")

  const uniqueFilters = isHotel
    ? findHotelFilters(data)
    : findInnerFilters(data)
  const dataToUse = isHotel
    ? data.allHotelsJson.edges
    : data.allProductsJson.edges
  const productsHook = useProducts(dataToUse, true)
  const imageData = data.file.childImageSharp.fluid
  const allProducts = productsHook.filteredProducts

  const buttons = data.markdownRemark.frontmatter.links.map((l, i) => {
    return <PageButton key={i} text={routes[l]} url={l} />
  })

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.markdownRemark.frontmatter.metaTitle}</title>
        <meta
          name="description"
          content={data.markdownRemark.frontmatter.metaDesc}
        />
        <html lang="fi" />
      </Helmet>
      <GlobalStyle>
        <HeaderWithPhoto
          image={imageData}
          alt={data.markdownRemark.frontmatter.filter}
        >
          <Header1>{data.markdownRemark.frontmatter.title}</Header1>
          <Header2>Minkälaista matkaa olet etsimässä?</Header2>
          <FilterHeader state={productsHook} filters={uniqueFilters} />
        </HeaderWithPhoto>
        {productsHook.isLoading ? (
          <LoadingText>Ladataan...</LoadingText>
        ) : (
          <>
            <a name="results"></a>
            <ResultHeader
              products={allProducts}
              hotels={isHotel}
              location={data.markdownRemark.frontmatter.path}
            />
          </>
        )}
        <Header3>Tutustu tarkemmin:</Header3>
        <ButtonContainer>{buttons}</ButtonContainer>
        <TextContainer>
          <Container
            dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
          ></Container>
        </TextContainer>
      </GlobalStyle>
    </>
  )
}

export const query = graphql`
  query($filter: String!, $img: String!, $path: String!) {
    allProductsJson(
      filter: { type: { eq: $filter } }
      sort: { fields: price, order: ASC }
    ) {
      edges {
        node {
          agency
          auto
          benefits
          city
          country
          departures
          desc
          id
          innerType
          length
          link
          name
          price
          return
          ship
          transport
          type
          slug
          shipImg
          visitTime
        }
      }
    }
    file(base: { eq: $img }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        filter
        img
        metaTitle
        metaDesc
        links
      }
    }
    allHotelsJson {
      edges {
        node {
          agency
          benefits
          city
          country
          desc
          id
          innerType
          link
          location
          name
          price
          rating
          type
        }
      }
    }
  }
`

export default Template
