export const routes = {
  "/risteilyt/": `Risteilyt`,
  "/reittimatkat/": `Reittimatkat`,
  "/kylpylaloma/": `Kylpylämatkat`,
  "/akkilahdot/": `Äkkilähdöt/Tarjoukset`,
  "/hotellimatka/": `Hotellimatkat`,
  "/hotellimatka/1-yo/": `Hotellimatka 1 yö`,
  "/hotellimatka/2-yota/": `Hotellimatka 2 yötä`,
  "/hotellimatka/3-yota/": `Hotellimatka 3 yötä`,
  "/kylpylaloma/1-yo/": `Kylpylämatka 1 yö`,
  "/kylpylaloma/2-yota/": `Kylpylämatka 2 yötä`,
  "/kylpylaloma/3-yota/": `Kylpylämatka 3 yötä`,
  "/risteilyt/22h/": `22 tunnin risteily`,
  "/risteilyt/mini/": `miniristeily`,
  "/risteilyt/paivaristeily/": `Päivämatka Tallinnaan`,
  "/reittimatkat/auto/": `Autolla Tallinnaan`,
  "/reittimatkat/lautat/": "Helsinki-Tallinna lautta",
  "/muut/ruotsin-risteily/": "Tukholman matkat",
  "/muut/ruotsin-risteily/turusta/": "Tukholman matkat Turusta",
  "/muut/ruotsin-risteily/helsingista/": "Tukholman matkat Helsingistä",
  "/muut/riikan-risteily/": "Riikan matkat",
  "/muut/pietarin-risteily/": "Pietarin risteily",
  "/muut/parnun-matkat/": "Pärnun matkat",
  "/": "Tallinnan matkat",
}
