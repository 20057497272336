import { useState } from "react"

export const useCheckbox = () => {
  const [checked, setChecked] = useState(false)

  const change = () => {
    setChecked(!checked)
  }

  const handleClick = (value, productState) => {
    const productHook = productState
    if (checked) {
      productHook.resetFilter()
      productHook.resetInnerFilter()
      productHook.resetProducts()
    } else {
      productHook.setNewInnerFilter(value)
        ? productHook.setNewInnerFilter(value)
        : productHook.setNewFilter(value)
    }
  }

  return {
    checked,
    change,
    handleClick,
  }
}
