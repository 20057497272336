export const findFilters = data => {
    const filters = data.allProductsJson.edges
      .map(product => product.node.type.includes("romanttinenviikonloppu") ? "risteily" : product.node.type)
    const uniqueFilters = [...new Set(filters)]
    return uniqueFilters
  }

  export const findInnerFilters = data => {
    const filters = data.allProductsJson.edges
      .map(product => product.node.innerType)
    const uniqueFilters = [...new Set(filters)]
    return uniqueFilters
  }
  export const findHotelFilters = data => {
    const filters = data.allHotelsJson.edges
      .map(hotel => hotel.node.innerType)
    const uniqueFilters = [...new Set(filters)]
    return uniqueFilters
  }
  