import React from "react"
import styled from "styled-components"
import Filter from "./Filter"
import { device } from "../../utils/mediaQueries"

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, 250px));
  grid-gap: 1px;
  text-align: center;
  margin: 30px 120px;
  justify-content: center;
  @media ${device.mobileL} {
    margin: 0px;
    margin-bottom: 20px;
    grid-template-columns: repeat(auto-fit, minmax(10px, 1500px));
  }
`
const HyperLink = styled.a`
  text-decoration: none;
`

const FilterHeader = ({ state, filters }) => {
  const uniqueFilters = Array.from(filters)
  const result = uniqueFilters.map((f, i) => {
    return (
      <HyperLink href="#results">
        <Filter key={i} f={f} i={i} state={state} />
      </HyperLink>
    )
  })

  return <Container>{result}</Container>
}

export default FilterHeader
