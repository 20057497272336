import { faShip } from "@fortawesome/free-solid-svg-icons"
import { faForward } from "@fortawesome/free-solid-svg-icons"
import { faHotel } from "@fortawesome/free-solid-svg-icons"
import { faSpa } from "@fortawesome/free-solid-svg-icons"
import { faPercent } from "@fortawesome/free-solid-svg-icons"
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons"
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons"
import { faGlassCheers } from "@fortawesome/free-solid-svg-icons"
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons"
import { faSuitcaseRolling } from "@fortawesome/free-solid-svg-icons"
import { faAnchor } from "@fortawesome/free-solid-svg-icons"
import { faWater } from "@fortawesome/free-solid-svg-icons"

export const icons = {
  risteily: faShip,
  reittimatka: faForward,
  hotellimatka: faHotel,
  kylpylämatka: faSpa,
  "tarjous/äkkilähtö": faPercent,
  "yksi suunta": faLongArrowAltRight,
  "meno-paluu": faExchangeAlt,
  "22h": faGlassCheers,
  päiväristeily: faShip,
  "miniristeily": faWater,
  "1 yö": faChevronCircleDown,
  "2 yötä": faChevronCircleDown,
  "3 yötä": faChevronCircleDown,
  "4 yötä": faChevronCircleDown,
  "1 yö laivalla ja 1 yö hotellissa": faChevronCircleDown,
  "kaupunki/kylpyläloma pärnussa": faHotel,
  "1 yö laivalla ja pärnun hotellimatka": faShip,
  "pärnun hotellimatka ja 1 yö tallinnassa": faSuitcaseRolling,
  "vuorokauden risteily helsingistä tallinnaan": faShip,
  "vuorokauden risteily turusta tukholmaan": faAnchor,
  "vuorokauden risteily turusta ahvenanmaalle": faWater,
  "riian hotellimatka": faHotel,
  "riian hotellimatka ja 1 yö tallinnassa": faSuitcaseRolling,
  "riian hotellimatka ja 1 yö laivalla": faShip,
  "hotellimatka helsingistä": faHotel,
  "hotellimatka turusta": faSuitcaseRolling,
  "risteily turusta": faShip,
  "risteily helsingistä": faWater,
  "hotelli": faHotel,
  "hostelli": faHotel,
  "huoneistohotelli": faHotel,
  "kylpylä": faSpa,
  "suomessa": faHotel,
  "ruotsissa": faShip,
  "virossa": faWater
}
