import React from "react"
import styled from "styled-components"
import { useCheckbox } from "../../hooks/useCheckbox"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { icons } from "../../utils/filterIcons"

const FilterStyled = styled.div`
  box-shadow: 2px 1px 6px 1px rgb(103, 104, 105);
  padding: 0.1rem;
  min-height: 130px;
  display: flex;
  border: 1px solid white;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  align-content: center;
  background-color: ${props => (props.active ? "#0470a8" : "white")};
  color: ${props => (props.active ? "white" : "#0481b1")};
  :active {
    background-color: #0481b1;
    color: white;
  }
  :hover {
    border: 1px solid #0481b1;
  }
`

const Text = styled.div`
  font-size: 16px;
  font-weight: 800;
  margin: 1rem;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 30px;
  margin: 1rem;
`

const Filter = props => {
  const checkbox = useCheckbox()
  const i = props.i
  const f = props.f
  return (
    <FilterStyled
      active={f === props.state.filter}
      id={i}
      onClick={() => checkbox.handleClick(f, props.state)}
      checked={f === props.state.filter}
    >
      <Icon icon={icons[props.f.toLowerCase()]} />
      <Text>{props.f.toUpperCase()}</Text>
    </FilterStyled>
  )
}

export default Filter
